import React from 'react';
import Skeleton from './Skeleton';
import { ComplianceListComponent } from '../types/ComplianceList';

const NlotComplianceList: ComplianceListComponent = () => (
    <Skeleton.Container>
        <Skeleton.Heading>Compliance</Skeleton.Heading>
        <Skeleton.List>
            <Skeleton.ListItem>
                De medewerker heeft zichzelf voorgesteld.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De medewerker heeft zichzelf gelegitimeerd met de medewerkerspas
                van de Naamloterij.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De medewerker heeft verteld dat het gaat om een bedrag per
                trekking, tot wederopzegging.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De medewerker heeft verteld dat er 14 trekkingen per jaar zijn.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De medewerker heeft verteld dat het gaat om een automatische
                incasso.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De medewerker heeft ter controle het ingevulde formulier samen
                met u doorlopen om de gemaakte afspraken te bevestigen.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De medewerker heeft verteld dat opzeggen door de deelnemer zelf
                gedaan dient te worden.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De medewerker heeft verteld dat u wordt gebeld om de gemaakte
                afspraken te controleren en om bovenstaande punten met u door te
                nemen.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De medewerker heeft verteld dat 80% van de opbrengst van de
                Naamloterij gaat naar de Hartstichting.
            </Skeleton.ListItem>
        </Skeleton.List>
    </Skeleton.Container>
);

export default NlotComplianceList;
