import React from 'react';
import Skeleton from './Skeleton';
import { ComplianceListComponent } from '../types/ComplianceList';

const SvhkLohComplianceList: ComplianceListComponent = () => (
    <Skeleton.Container>
        <Skeleton.Heading>Compliance</Skeleton.Heading>
        <Skeleton.List>
            <Skeleton.ListItem>
                De werver heeft zichzelf voorgesteld.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De werver heeft zichzelf gelegitimeerd met zijn of haar
                werverspas.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De werver heeft verteld dat het gaat om een bedrag van €12,50
                per lot, tot wederopzegging.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De werver heeft verteld dat er 16 trekkingen per jaar zijn.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De werver heeft verteld dat het om een automatische incasso
                gaat.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De werver heeft ter controle het ingevulde formulier samen met u
                doorlopen om de gemaakte afspraken te confirmeren.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De werver heeft verteld dat opzeggen door de deelnemer zelf
                gedaan dient te worden, per mail of via de telefoon.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De werver heeft verteld dat u wordt gebeld om de gemaakte
                afspraken te controleren en om bovenstaande punten met u door te
                nemen.
            </Skeleton.ListItem>
            <Skeleton.ListItem>
                De werver heeft verteld dat u uw lotnummer ontvangt per mail
                binnen 2 weken.
            </Skeleton.ListItem>
        </Skeleton.List>
    </Skeleton.Container>
);

export default SvhkLohComplianceList;
