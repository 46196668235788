import { useLingui } from '@lingui/react';
import { MessageDescriptor, MessageOptions, I18n } from '@lingui/core';
import { useMemo } from 'react';

export type TranslateFunction = (
    id: MessageDescriptor | string,
    values?: Record<string, any>,
    options?: MessageOptions,
) => string;

export default function useTranslate(): TranslateFunction & { i18n: I18n } {
    const { i18n } = useLingui();

    return useMemo(() => {
        const t = i18n._.bind(i18n) as TranslateFunction;
        return Object.assign(t, { i18n });
    }, [i18n]);
}
