import useActiveCampaign from '@hooks/useActiveCampaign';
import React from 'react';
import Interval from '@constants/interval';
import useTranslate from '@hooks/useTranslate';
import Skeleton from './Skeleton';
import { ComplianceListComponent } from '../types/ComplianceList';
import translations from './TMGNoQrComplianceList.trans';
import commonTranslations, {
    intervalTranslations,
} from '../ComplianceList.trans';

const TMGNoQrComplianceList: ComplianceListComponent = ({ interval }) => {
    const translate = useTranslate();
    const campaign = useActiveCampaign(true);

    return (
        <Skeleton.Container>
            <Skeleton.Heading>
                {translate(commonTranslations.heading)}
            </Skeleton.Heading>
            <Skeleton.List>
                <Skeleton.ListItem>
                    {translate(translations.listItem1)}
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    {translate(translations.listItem2, {
                        campaignName: campaign.name,
                    })}
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    {translate(translations.listItem3, {
                        interval: translate(intervalTranslations[interval]),
                    })}
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    {translate(translations.listItem4)}
                </Skeleton.ListItem>
                <Skeleton.ListItem>
                    {translate(translations.listItem5)}
                </Skeleton.ListItem>
                {interval !== Interval.oneTime && (
                    <>
                        <Skeleton.ListItem>
                            {translate(translations.listItem6)}
                        </Skeleton.ListItem>
                        <Skeleton.ListItem>
                            {translate(translations.listItem7)}
                        </Skeleton.ListItem>
                    </>
                )}
            </Skeleton.List>
        </Skeleton.Container>
    );
};

export default TMGNoQrComplianceList;
