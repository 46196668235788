import { defineMessage } from '@lingui/macro';
import { toTranslationRecord } from '@utilities/translationUtilities';
import Interval from '@constants/interval';
import { MessageDescriptor } from '@lingui/core';

const translations = {
    heading: defineMessage({
        id: 'wizard.form.compliance.heading',
        message: 'Compliance',
        comment: 'Heading for the compliance list',
    }),
};

export default toTranslationRecord<keyof typeof translations>(translations);

export const intervalTranslations: Record<Interval, MessageDescriptor> = {
    [Interval.yearly]: defineMessage({
        id: 'wizard.form.compliance.interval.yearly',
        message: 'a yearly amount, until further notice',
        comment: 'Pledge cases status lead',
    }),
    [Interval.halfYearly]: defineMessage({
        id: 'wizard.form.compliance.interval.half-yearly',
        message: 'a half-yearly amount, until further notice',
        comment: 'Pledge cases status active',
    }),
    [Interval.quarterly]: defineMessage({
        id: 'wizard.form.compliance.interval.quarterly',
        message: 'a quarterly amount, until further notice',
        comment: 'Pledge cases status dropped',
    }),
    [Interval.monthly]: defineMessage({
        id: 'wizard.form.compliance.interval.monthly',
        message: 'a monthly amount, until further notice',
        comment: 'Pledge cases status cancelled',
    }),
    [Interval.oneTime]: defineMessage({
        id: 'wizard.form.compliance.interval.one-time',
        message: 'a one-time donation',
        comment: 'Pledge cases status fulfilled',
    }),
};
