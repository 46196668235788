import { defineMessage } from '@lingui/macro';
import { toTranslationRecord } from '@utilities/translationUtilities';

const translations = {
    listItem1: defineMessage({
        id: 'wizard.form.compliance.tmgNoQr.list-item-1',
        message: 'The recruiter has introduced himself.',
        comment: 'TMG compliance list item 1',
    }),
    listItem2: defineMessage({
        id: 'wizard.form.compliance.tmgNoQr.list-item-2',
        message:
            'The recruiter has identified himself with the recruiter pass of {campaignName}.',
        comment: 'TMG compliance list item 2',
    }),
    listItem3: defineMessage({
        id: 'wizard.form.compliance.tmgNoQr.list-item-3',
        message: 'The recruiter has told you that it is {interval}.',
        comment: 'TMG compliance list item 3',
    }),
    listItem4: defineMessage({
        id: 'wizard.form.compliance.tmgNoQr.list-item-4',
        message:
            'The recruiter has gone through the completed form with you to confirm the agreements made.',
        comment: 'TMG compliance list item 4',
    }),
    listItem5: defineMessage({
        id: 'wizard.form.compliance.tmgNoQr.list-item-5',
        message: 'The recruiter has told you that it is a direct debit.',
        comment: 'TMG compliance list item 5',
    }),
    listItem6: defineMessage({
        id: 'wizard.form.compliance.tmgNoQr.list-item-6',
        message:
            'The recruiter has told you that the donor must cancel themselves, by email or by phone.',
        comment: 'TMG compliance list item 6',
    }),
    listItem7: defineMessage({
        id: 'wizard.form.compliance.tmgNoQr.list-item-7',
        message:
            'The recruiter has told you that you will be called to check the agreements made and to go through the above points with you.',
        comment: 'TMG compliance list item 7',
    }),
};

export default toTranslationRecord<keyof typeof translations>(translations);
