import React from 'react';
import { Trans } from '@lingui/macro';

export const getComplianceListErrorNotificationLabels = () => ({
    error: (
        <Trans
            id="wizard.form.compliance.error"
            comment="Text that is shown when the compliance list could not be loaded"
        >
            The compliance list could not be loaded. Update your version of the
            Field App to resolve this issue.
        </Trans>
    ),
});
