import LOHComplianceList from '../components/LOHComplianceList';
import TMGNoQrComplianceList from '../components/TMGNoQrComplianceList';
import TMGQrComplianceList from '../components/TMGQrComplianceList';
import { ComplianceListComponent } from '../types/ComplianceList';
import KikaComplianceList from '../components/KikaComplianceList';
import SvhkLohComplianceList from '../components/SVHKLOHComplianceList';
import NlotComplianceList from '../components/NlotComplianceList';

export enum ComplianceListIdentifier {
    LOH = 'loh',
    SvhkLoh = 'svhkLoh',
    TMGNoQR = 'tmgNoQr',
    TMGQr = 'tmgQr',
    Kika = 'kika',
    Nlot = 'nlot',
}

const complianceListRegistry: Record<
    ComplianceListIdentifier,
    ComplianceListComponent
> = {
    [ComplianceListIdentifier.LOH]: LOHComplianceList,
    [ComplianceListIdentifier.SvhkLoh]: SvhkLohComplianceList,
    [ComplianceListIdentifier.Kika]: KikaComplianceList,
    [ComplianceListIdentifier.TMGNoQR]: TMGNoQrComplianceList,
    [ComplianceListIdentifier.TMGQr]: TMGQrComplianceList,
    [ComplianceListIdentifier.Nlot]: NlotComplianceList,
};

export function resolveComplianceListComponentOrThrow(
    identifier: ComplianceListIdentifier,
): ComplianceListComponent {
    const Component = complianceListRegistry[identifier];

    if (!Component) {
        throw new Error(
            `No compliance list component found for identifier ${identifier}`,
        );
    }

    return Component;
}
