import React, { FC } from 'react';
import Alert from '@primitives/alert/Alert';
import { getComplianceListErrorNotificationLabels } from './ComplianceListErrorNotification.trans';

const ComplianceListErrorNotification: FC = () => {
    const labels = getComplianceListErrorNotificationLabels();

    return <Alert>{labels.error}</Alert>;
};

export default ComplianceListErrorNotification;
