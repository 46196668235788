import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { withErrorBoundary } from 'react-error-boundary';
import Interval from '@constants/interval';
import { captureError } from '@utilities/errorCapturingUtilities';
import useResolveComplianceListComponentForActiveCampaign from './hooks/useResolveComplianceListComponent';
import { WizardFormState } from '../../types/wizardFormState';
import ComplianceListErrorNotification from './components/ComplianceListErrorNotification';

type Props = {
    form: UseFormMethods<WizardFormState>;
};

const ComplianceList: FC<Props> = ({ form }) => {
    const interval = form.watch('interval');

    const ComplianceListForCampaign =
        useResolveComplianceListComponentForActiveCampaign();

    if (!interval || !ComplianceListForCampaign) {
        return null;
    }

    return <ComplianceListForCampaign interval={interval as Interval} />;
};

const ComplianceListWithErrorBoundary = withErrorBoundary(ComplianceList, {
    FallbackComponent: ComplianceListErrorNotification,
    onError: (error, info) => {
        captureError(error, {
            extra: info,
            level: 'info',
        });
    },
});

export default ComplianceListWithErrorBoundary;
